/* eslint-disable import/no-unused-modules */
import {EventEmitter} from 'events'
import domready from 'domready'
import type Widget from 'widgetly/lib/Widget'
import type {Size} from '@clappr/core'
import {getUrlParams} from '@rambler-id/url'
import {type Options, Events, name as widgetName} from 'widgets/player'
import {
  AutoPlay,
  Position,
  VerticalDirection,
  MediaControlSkinName
} from 'common/api/records'
import {getMediator} from './mediator'

EventEmitter.defaultMaxListeners = Infinity

/**
 * JavaScript API для встраиваемых видео
 *
 * ```js
 * const player = new RamblerPlayer({
 *   parentId: '#player',
 *   id: 'record::9afb91a9-999a-9d9a-b9f9-b9f99999d51b'
 * })
 * player.on(RamblerPlayer.Events.PLAYER_READY, () => {
 *   // плеер полностью готов к старту
 * })
 * ```
 */
export class RamblerPlayer {
  /**
   * Интерфейс плеера предоставляет часть методов [EventEmitter](https://nodejs.org/api/events.html#events_class_eventemitter),
   * что позволяет прослушивать события плеера с помощью JavaScript API.
   *
   * ```js
   * const player = new RamblerPlayer({
   *   parentId: '#player',
   *   id: 'record::9afb91a9-999a-9d9a-b9f9-b9f99999d51b'
   * })
   * player.on(RamblerPlayer.Events.PLAYER_READY, () => {
   *   // плеер готов к старту
   * })
   * player.on(RamblerPlayer.Events.PLAYER_PLAY, () => {
   *   // плеер начал проигрывание
   * })
   * player.on(RamblerPlayer.Events.PLAYER_PAUSE, () => {
   *   // плеер встал на паузу
   * })
   * ```
   */
  public static Events = Events

  /**
   * Позволяет инициализировать плеер в любое время,
   * например, перед асинхронной загрузкой скрипта SDK.
   *
   * ```html
   * <div id="player"></div>
   * <script>
   *   window.RamblerPlayer = window.RamblerPlayer || []
   *   window.RamblerPlayer.push(() => {
   *     const player = new RamblerPlayer({
   *       parentId: '#player',
   *       id: 'record::9afb91a9-999a-9d9a-b9f9-b9f99999d51b'
   *     })
   *     player.on(RamblerPlayer.Events.PLAYER_READY, () => {
   *       // плеер полностью готов к старту
   *     })
   *   })
   * </script>
   * <script async src="https://vp.rambler.ru/player/sdk.js"></script>
   * ```
   */
  public static push(callback: () => void): void {
    callback()
  }

  /**
   * Convert legacy player embed to current version
   */
  protected static fallback(): void {
    const frames: HTMLIFrameElement[] = [].slice.call(
      document.querySelectorAll('iframe[src*="media.eagleplatform.com"]')
    )

    frames.forEach((frame) => {
      const {parentElement} = frame
      const query = getUrlParams(frame.src)
      const element = document.createElement('div')

      element.dataset.widget = widgetName

      if (query.record_id) {
        element.dataset.id = query.record_id.toString()
      }

      if (query.id) {
        element.dataset.id = query.id.toString()
      }

      if (query.ad_template_id) {
        element.dataset.adTemplateId = query.ad_template_id.toString()
      }

      if (query.player_template_id) {
        element.dataset.playerTemplateId = query.player_template_id.toString()
      }

      if (query.multiplayer_filter_id) {
        element.dataset.multiplayerFilterId =
          query.multiplayer_filter_id.toString()
      }

      if (query.stream) {
        element.dataset.live = query.stream.toString()
      }

      parentElement?.removeChild(frame)
      parentElement?.appendChild(element)
    })
  }

  /**
   * Get source links to open videos in admin with snippet
   *
   * javascript:(function(){
   *   Promise.all([
   *     Promise.resolve([].slice.call(document.querySelectorAll('iframe[src*="media.eagleplatform.com"]'))
   *       .map(function (frame) {
   *         var match = frame.src.match(/https:\/\/([^.]+).+[?&](?:record_id|id)=(\d+)/)
   *         return match && 'https://' + match[1] + '.new.eagleplatform.com/records/' + match[2] + '/attributes'
   *       })),
   *     'RamblerPlayer' in window ? RamblerPlayer.getSourceLinks() : Promise.resolve([])
   *   ]).then(function (urls) {
   *     urls[0].concat(urls[1]).forEach(function (url) {
   *       window.open(url, '_blank')
   *     })
   *   })
   * })()
   */

  protected static getSourceLinks(): Promise<string[]> {
    const instances = getMediator().getWidgetInstances()

    return Promise.all(
      Object.keys(instances).map((key) =>
        instances[key]?.iframe?.consumer?.getSourceLink()
      )
    )
  }

  private emitter = new EventEmitter()
  private consumer: any
  private containerElement: any

  public constructor(options: Options) {
    const {parent, parentId, ...params} = options

    this.containerElement = parentId ? document.querySelector(parentId) : parent

    if (!this.containerElement) {
      throw new Error(
        'missed parent element that the player should be inserted into'
      )
    }

    let widgetPromise

    if (this.containerElement.rcWidget) {
      widgetPromise = Promise.resolve(this.containerElement.rcWidget)
    } else if (this.containerElement.dataset.widget === widgetName) {
      widgetPromise = new Promise<Widget<Options> | null>((resolve) =>
        domready(() => resolve(this.containerElement.rcWidget))
      )
    } else {
      widgetPromise = Promise.resolve(null)
    }

    this.containerElement.rcWidgetPromise ??= (async () => {
      const widget = await widgetPromise

      if (widget) {
        await new Promise<void>((resolve) =>
          widget.iframe?.consumer
            ? resolve()
            : widget.iframe.transport.once('ready', resolve)
        )

        return widget.externalize()
      }

      return getMediator().buildWidget(
        widgetName,
        this.containerElement,
        params
      )
    })()

    // NOTE: заинлайнено в конструкторе, чтобы не создавать публичные методы SDK
    ;(async () => {
      this.consumer = await this.containerElement.rcWidgetPromise

      Object.keys(RamblerPlayer.Events).forEach((eventKey) => {
        const eventType =
          RamblerPlayer.Events[eventKey as keyof typeof RamblerPlayer.Events]

        if (typeof eventType === 'string') {
          this.consumer.on(eventType, (...args: any[]) => {
            this.emitter.emit(eventType, ...args)
          })
        }
      })
    })()
  }

  /**
   * Переключение полноэкранного режима
   */
  public toggleFullscreen(): Promise<void> {
    return this.consumer?.toggleFullscreen()
  }

  /**
   * Изменение размера текущего видео
   */
  public resize(size: Pick<Size, 'height'>): Promise<void> {
    return this.consumer?.resize(size)
  }

  /**
   * Запуск проигрывания текущего видео
   */
  public play(): Promise<void> {
    return this.consumer?.play()
  }

  /**
   * Установка паузы текущего видео
   */
  public pause(): Promise<void> {
    return this.consumer?.pause()
  }

  /**
   * Остановка воспроизведения текущего видео
   */
  public stop(): Promise<void> {
    return this.consumer?.stop()
  }

  /**
   * Перемотка текущего видео до указанного времени. Например, `player.seek(120)`
   * перемотает к времени 120 секунд (2 минуты).
   */
  public seek(value: number): Promise<void> {
    return this.consumer?.seek(value)
  }

  /**
   * Перемотка текущего видео до указанного времени в процентах. Например, `player.seekPercentage(50)`
   * перемотает к середине видео.
   */
  public seekPercentage(percentage: number): Promise<void> {
    return this.consumer?.seekPercentage(percentage)
  }

  /**
   * Выключение звука текущего видео
   */
  public mute(): Promise<void> {
    return this.consumer?.mute()
  }

  /**
   * Включение звука текущего видео
   */
  public unmute(): Promise<void> {
    return this.consumer?.unmute()
  }

  /**
   * Установка громкости текущего видео. Громкость должна быть
   * числом между 0 и 100, где 0 - без звука, 100 - максимальная громкость.
   */
  public setVolume(value: number): Promise<void> {
    return this.consumer?.setVolume(value)
  }

  /**
   * Настройка плеера после его создания
   */
  public configure(options: Options): Promise<void> {
    return this.consumer?.configure(options)
  }

  /**
   * Проверка, что плеер готов к запуску
   */
  public isReady(): Promise<boolean> {
    return this.consumer?.isReady()
  }

  /**
   * Проверка, что плеер проигрывает видео
   */
  public isPlaying(): Promise<boolean> {
    return this.consumer?.isPlaying()
  }

  /**
   * Получение громкости текущего видео. Громкость должна быть
   * числом между 0 и 100, где 0 - без звука, 100 - максимальная громкость.
   */
  public getVolume(): Promise<number> {
    return this.consumer?.getVolume()
  }

  /**
   * Получение текущего времени проигрывания в секундах
   */
  public getCurrentTime(): Promise<number> {
    return this.consumer?.getCurrentTime()
  }

  /**
   * Время, которое представляет "0" относительно начала воспроизведения.
   * Для потока с sliding window это значение будет увеличиваться
   * по мере удаления содержимого с начала
   */
  public getStartTimeOffset(): Promise<number> {
    return this.consumer?.getStartTimeOffset()
  }

  /**
   * Получение длительности видео в секундах
   */
  public getDuration(): Promise<number> {
    return this.consumer?.getDuration()
  }

  /**
   * Постоянное прослушивание события
   */
  public on(eventType: string, listener: (...args: any[]) => void): void {
    this.emitter.on(eventType, listener)
  }

  /**
   * Прослушивание события один раз
   */
  public once(eventType: string, listener: (...args: any[]) => void): void {
    this.emitter.once(eventType, listener)
  }

  /**
   * Остановка прослушивания события
   */
  public removeListener(
    eventType: string,
    listener: (...args: any[]) => void
  ): void {
    this.emitter.removeListener(eventType, listener)
  }

  /**
   * Уничтожение текущего плеер и удаление его из DOM
   */
  public destroy(): Promise<void> {
    delete this.containerElement?.rcWidget
    delete this.containerElement?.rcWidgetPromise

    return this.consumer?.destroy()
  }
}

export {
  Events,
  Options,
  AutoPlay,
  Position,
  VerticalDirection,
  MediaControlSkinName
}
