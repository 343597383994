import WidgetlyMediator, {
  type MediatorUserInterface
} from 'widgetly/lib/Mediator'
import {hasFullscreenElement} from 'utils/browser'
import * as optionsStorage from 'utils/optionsStorage'
import type {MediatorProperties} from 'common/types/provider'
import * as widgets from 'widgets'

class Mediator<O, P> extends WidgetlyMediator<O, P> {
  public buildWidget = async <Params>(
    name: string,
    containerElement?: HTMLElement | string,
    params?: Params
  ): Promise<any> => {
    if (name in widgets) {
      return await super.buildWidget(name, containerElement, params)
    }
  }
}

let api: MediatorUserInterface<Record<string, unknown>, MediatorProperties>

const DOCKING_PARENT_ID = process.env.COMMIT

export const getMediator = (): MediatorUserInterface<
  Record<string, unknown>,
  MediatorProperties
> => {
  if (!api) {
    const mediator = new Mediator<Record<string, unknown>, MediatorProperties>(
      {},
      {
        state: {},

        canPlay(playerId: string, parentId?: string): boolean {
          // NOTE: one page can contain some players or some groups of some players
          // with one parent. if parent unknown, player use whole height of the
          // document. in this case only first player can play and dock, other wait
          // until the first one is paused and undocked. if parent is passed in
          // options, players have related behavior within each parent
          const resultParentId = parentId ?? DOCKING_PARENT_ID

          this.state[resultParentId] ??= playerId

          return (
            !this.state[resultParentId] ||
            this.state[resultParentId] === playerId
          )
        },

        stopPlaying(playerId: string, parentId?: string): void {
          const resultParentId = parentId ?? DOCKING_PARENT_ID

          if (this.state[resultParentId] === playerId) {
            delete this.state[resultParentId]
          }
        },

        hasFullscreen(): boolean {
          return hasFullscreenElement() || (this.state.fullscreen as boolean)
        },

        toggleFullscreen(fullscreen: boolean): void {
          this.state.fullscreen = fullscreen
        },

        disableDocking(): void {
          this.emit('disableDocking', true)
        },

        enforceStart(playerId: string, parentId?: string): void {
          const resultParentId = parentId ?? DOCKING_PARENT_ID

          this.state[resultParentId] = playerId

          this.emit('enforceStart', playerId)
        },

        getWidgetInstances(): any[] {
          return (this as any).widgetInstances
        },

        setOptionStorage(key, value) {
          optionsStorage.set(key, value)
          this.emit(`changeOptions:${key}`, value)
        },

        getOptionStorage(name): any {
          return optionsStorage.get(name)
        }
      }
    )

    Object.keys(widgets).forEach((key) => {
      const {defineWidget} = (widgets as any)[key]

      defineWidget?.(mediator)
    })

    api = mediator.externalize()
  }

  return api
}
