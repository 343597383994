import {loadScript} from 'utils/dom'

const CDN_ORIGIN = 'https://id.rambler.ru'

let ramblerIdHelper: any
let ramblerIdHelperPromise: Promise<any>

const getRamblerIdHelper = async (): Promise<any> => {
  if (!ramblerIdHelper) {
    if (!ramblerIdHelperPromise) {
      ramblerIdHelperPromise = (async () => {
        window.ramblerIdHelper = window.ramblerIdHelper || []

        await Promise.all([
          new Promise<void>((resolve) => {
            window.ramblerIdHelper.push(() => {
              ramblerIdHelper = window.ramblerIdHelper
              resolve()
            })
          }),
          loadScript(`${CDN_ORIGIN}/rambler-id-helper/auth_events.js`)
        ])
      })()
    }

    try {
      await ramblerIdHelperPromise
    } catch {}
  }

  return ramblerIdHelper
}

export const getSessionToken = async (): Promise<any> => {
  const ramblerIdHelper = await getRamblerIdHelper()

  return new Promise((resolve, reject) => {
    ramblerIdHelper.getSessionToken((result: any, error: Error) => {
      if (error) {
        reject(error)
      } else {
        resolve(result)
      }
    })
  })
}
