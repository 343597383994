import throttle from 'raf-throttle'
import {getVisibleArea} from 'widgetly/lib/utils/DOM/viewport'
import {
  globalViewportEvents,
  mutationEvents,
  ViewportManager,
  setMutationParams
} from 'widgetly/lib/utils/DOM'

export type Rect = Pick<
  DOMRect,
  'width' | 'height' | 'left' | 'top' | 'right' | 'bottom'
>

setMutationParams({
  childList: true,
  subtree: true,
  attributes: true
})

export const injectStyle = (
  cssText: string,
  scope?: string
): HTMLStyleElement => {
  const style = document.createElement('style')

  style.type = 'text/css'
  style.textContent = cssText

  if (scope) {
    style.setAttribute('data-scope', scope)
  }

  return document.head.appendChild(style)
}

export const loadStyle = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url)

    return await response.text()
  } catch {
    return ''
  }
}

export const loadScript = (src: string): Promise<HTMLScriptElement> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')

    script.src = src
    script.async = true
    script.type = 'text/javascript'

    script.onload = () => {
      resolve(script)
    }

    script.onerror = () => {
      reject(new Error(`script not loaded: ${src}`))
    }

    document.body.appendChild(script)
  })
}

const findScrollableParent = (
  element: HTMLElement,
  noCheckScrollHeight: boolean
): HTMLElement => {
  const parentElement = element?.parentElement

  if (!parentElement || parentElement === document.documentElement) {
    return document.documentElement
  }

  if (
    noCheckScrollHeight ||
    parentElement.scrollHeight > parentElement.clientHeight ||
    parentElement === document.body ||
    parentElement === document.documentElement
  ) {
    const overflowY = getComputedStyle(parentElement).overflowY

    if (overflowY === 'auto' || overflowY === 'scroll') {
      return parentElement
    }
  }

  return findScrollableParent(parentElement, noCheckScrollHeight)
}

export const createViewportManager = (
  element: HTMLElement,
  callback: (...args: any[]) => any
): ViewportManager => {
  const throttledCallback = throttle(callback)
  let parentElement: HTMLElement
  let subscribedOnParentScroll: boolean

  if (element) {
    parentElement = findScrollableParent(element, true)

    if (
      parentElement &&
      parentElement !== document.body &&
      parentElement !== document.documentElement
    ) {
      parentElement.addEventListener('scroll', throttledCallback, false)
      subscribedOnParentScroll = true
    }
  }

  globalViewportEvents.on('change', throttledCallback)
  mutationEvents.on('mutation', throttledCallback)
  document.body.addEventListener('transitionend', throttledCallback)

  return {
    destroy() {
      globalViewportEvents.removeListener('change', throttledCallback)
      mutationEvents.removeListener('mutation', throttledCallback)
      document.body.removeEventListener('transitionend', throttledCallback)

      if (subscribedOnParentScroll) {
        parentElement.removeEventListener('scroll', throttledCallback, false)
      }
    }
  }
}

export const getVisibleAreaXYZ = (element: HTMLElement): Rect => {
  let visibleArea = getVisibleArea(element)
  const clientRect = element.getBoundingClientRect()

  // NOTE: current element is visible if it's on top of the document z-index stack
  const isElementOnTop =
    visibleArea.height > 0 &&
    element ===
      document.elementFromPoint(
        // NOTE: get a top element from center of the current element visible area
        Math.ceil(clientRect.left + visibleArea.left + visibleArea.width / 2),
        Math.ceil(clientRect.top + visibleArea.top + visibleArea.height / 2)
      )

  if (!isElementOnTop) {
    visibleArea = {...visibleArea, width: 0, height: 0}
  }

  return visibleArea
}

export {ViewportManager, globalViewportEvents, getVisibleArea}
