// partial copy of clappr events map to reduce facade size

/**
 * События плеера, которые прослушиваются посредством JavaScript API
 */
export enum Events {
  /**
   * Плеер готов к запуску
   */
  PLAYER_READY = 'ready',
  /**
   * Плеер изменил размер
   */
  PLAYER_RESIZE = 'resize',
  /**
   * Плеер изменил состояние полноэкранного режима
   */
  PLAYER_FULLSCREEN = 'fullscreen',
  /**
   * Плеер переключился в режим мини-плеера или вышел из него
   */
  PLAYER_DOCK = 'dock',
  /**
   * Плеер начал воспроизведение
   */
  PLAYER_PLAY = 'play',
  /**
   * Плеер встал на паузу
   */
  PLAYER_PAUSE = 'pause',
  /**
   * Плеер остановил воспроизведение
   */
  PLAYER_STOP = 'stop',
  /**
   * Плеер закончил воспроизведение
   */
  PLAYER_ENDED = 'ended',
  /**
   * Произошла перемотка проигрывания в плеере
   */
  PLAYER_SEEK = 'seek',
  /**
   * Возникла ошибка при проигрывании в плеере
   */
  PLAYER_ERROR = 'playererror',
  /**
   * Плеер обновил время проигрывания
   */
  PLAYER_TIMEUPDATE = 'timeupdate',
  /**
   * Плеер обновил уровень громкости
   */
  PLAYER_VOLUMEUPDATE = 'volumeupdate',
  /**
   * Плеер уничтожился
   */
  PLAYER_DESTROY = 'destroy',
  /**
   * Реклама начала проигрывание
   */
  ADVERTISEMENT_STARTED = 'advertisement:started',
  /**
   * Реклама встала на паузу
   */
  ADVERTISEMENT_PAUSED = 'advertisement:paused',
  /**
   * Реклама продолжила проигрывание после паузы
   */
  ADVERTISEMENT_RESUMED = 'advertisement:resumed',
  /**
   * Реклама завершила проигрывание
   */
  ADVERTISEMENT_ENDED = 'advertisement:ended',
  /**
   * Возникла ошибка при проигрывании рекламы
   */
  ADVERTISEMENT_ERROR = 'advertisement:error'
}
