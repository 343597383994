const BOT_USER_AGENT = [
  'googlebot/',
  'Googlebot-Mobile',
  'Googlebot-Image',
  'Google favicon',
  'Mediapartners-Google',
  'bingbot',
  'slurp',
  'java',
  'wget',
  'curl',
  'Commons-HttpClient',
  'Python-urllib',
  'libwww',
  'httpunit',
  'nutch',
  'phpcrawl',
  'msnbot',
  'jyxobot',
  'FAST-WebCrawler',
  'FAST Enterprise Crawler',
  'biglotron',
  'teoma',
  'convera',
  'seekbot',
  'gigablast',
  'exabot',
  'ngbot',
  'ia_archiver',
  'GingerCrawler',
  'webmon',
  'httrack',
  'webcrawler',
  'grub.org',
  'UsineNouvelleCrawler',
  'antibot',
  'netresearchserver',
  'speedy',
  'fluffy',
  'bibnum.bnf',
  'findlink',
  'msrbot',
  'panscient',
  'yacybot',
  'AISearchBot',
  'IOI',
  'ips-agent',
  'tagoobot',
  'MJ12bot',
  'dotbot',
  'woriobot',
  'yanga',
  'buzzbot',
  'mlbot',
  'yandexbot',
  'purebot',
  'Linguee Bot',
  'Voyager',
  'CyberPatrol',
  'voilabot',
  'baiduspider',
  'citeseerxbot',
  'spbot',
  'twengabot',
  'postrank',
  'turnitinbot',
  'scribdbot',
  'page2rss',
  'sitebot',
  'linkdex',
  'Adidxbot',
  'blekkobot',
  'ezooms',
  'dotbot',
  'Mail.RU_Bot',
  'discobot',
  'heritrix',
  'findthatfile',
  'europarchive.org',
  'NerdByNature.Bot',
  'sistrix crawler',
  'ahrefsbot',
  'Aboundex',
  'domaincrawle',
  'wbsearchbot',
  'summify',
  'ccbot',
  'edisterbot',
  'seznambot',
  'ec2linkfinder',
  'gslfbot',
  'aihitbot',
  'intelium_bot',
  'facebookexternalhit',
  'yeti',
  'RetrevoPageAnalyzer',
  'lb-spider',
  'sogou',
  'lssbot',
  'careerbot',
  'wotbox',
  'wocbot',
  'ichiro',
  'DuckDuckBot',
  'lssrocketcrawler',
  'drupact',
  'webcompanycrawler',
  'acoonbot',
  'openindexspider',
  'gnam gnam spider',
  'web-archive-net.com.bot',
  'backlinkcrawler',
  'coccoc',
  'integromedb',
  'content crawler spider',
  'toplistbot',
  'seokicks-robot',
  'it2media-domain-crawler',
  'ip-web-crawler.com',
  'siteexplorer.info',
  'elisabot',
  'proximic',
  'changedetection',
  'blexbot',
  'arabot',
  'WeSEE:Search',
  'niki-bot',
  'CrystalSemanticsBot',
  'rogerbot',
  '360Spider',
  'psbot',
  'InterfaxScanBot',
  'Lipperhey SEO Service',
  'CC Metadata Scaper',
  'g00g1e.net',
  'GrapeshotCrawler',
  'urlappendbot',
  'brainobot',
  'fr-crawler',
  'binlar',
  'SimpleCrawler',
  'Livelapbot',
  'Twitterbot',
  'cXensebot',
  'smtbot',
  'bnf.fr_bot',
  'A6-Indexer',
  'ADmantX',
  'Facebot',
  'Twitterbot',
  'OrangeBot',
  'memorybot',
  'AdvBot',
  'MegaIndex',
  'SemanticScholarBot',
  'ltx71',
  'nerdybot',
  'xovibot',
  'BUbiNG',
  'Qwantify',
  'archive.org_bot',
  'Applebot',
  'TweetmemeBot',
  'crawler4j',
  'findxbot',
  'SemrushBot',
  'yoozBot',
  'lipperhey',
  'y!j-asr',
  'Domain Re-Animator Bot',
  'AddThis'
]

export const isSearchBot = Boolean(
  // eslint-disable-next-line security/detect-non-literal-regexp
  navigator.userAgent.match(new RegExp(`(${BOT_USER_AGENT.join('|')})`, 'i'))
)

export const hasTrackingPrevention =
  'requestStorageAccess' in document &&
  typeof (document as any).requestStorageAccess === 'function'

export const hasFullscreenElement = (): boolean =>
  Boolean(
    document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
  )

const WEBVIEW_MATCH = ['RVPCompat\\w+/[0-9.]+', 'Gazeta.Ru \\w+ Application']

export const enum BrowserType {
  BROWSER = 'browser',
  WEBVIEW = 'webview'
}

export const getBrowserType = (): BrowserType => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const matchWebView = new RegExp(`(${WEBVIEW_MATCH.join('|')})`, 'i')

  return navigator.userAgent.match(matchWebView)
    ? BrowserType.WEBVIEW
    : BrowserType.BROWSER
}
