export const getCookie = (name: string): null | string => {
  try {
    return document.cookie.split(';').reduce((result: null | string, item) => {
      if (result == null) {
        const [key, ...rest] = item.trim().split('=')

        if (key === name) {
          return rest.join('=')
        }
      }

      return result
    }, null)
  } catch {
    return null
  }
}

export interface CookieOptions {
  domain?: string
  path?: string
  expires?: string
  sameSite?: string
  secure?: boolean
}

export const setCookie = (
  name: string,
  value: string,
  options: CookieOptions = {}
): void => {
  try {
    let cookieString = `${name}=${value}`

    if (options.domain) {
      cookieString += `; domain=${options.domain}`
    }

    if (options.path) {
      cookieString += `; path=${options.path}`
    }

    if (options.expires) {
      cookieString += `; expires=${options.expires}`
    }

    if (options.sameSite) {
      cookieString += `; SameSite=${options.sameSite}`
    }

    if (options.secure) {
      cookieString += `; Secure`
    }

    document.cookie = cookieString
  } catch {}
}
