import {getItem, setItem} from '@rambler-id/local-storage'

const STORAGE_KEY = 'vpPlayer:optionsStorage'

interface Storage {
  volume?: number
  channel?: string
}

type StorageKeys = keyof Storage

export interface OptionsStorage {
  get: <K extends StorageKeys>(key: K) => Storage[K] | null
  set: <K extends StorageKeys>(key: K, value: Storage[K]) => void
  remove: <K extends StorageKeys>(key: K) => void
}

export const get: OptionsStorage['get'] = (key) => {
  const storage: Storage | null = getItem(STORAGE_KEY)

  return storage?.[key] ?? null
}

export const set: OptionsStorage['set'] = (key, value) => {
  const storage: Storage | null = getItem(STORAGE_KEY)

  setItem(STORAGE_KEY, {
    ...storage,
    [key]: value
  })
}

export const remove: OptionsStorage['remove'] = (key) => {
  const storage: Storage | null = getItem(STORAGE_KEY)

  delete storage?.[key]

  setItem(STORAGE_KEY, {
    ...storage
  })
}
