import {isDebug, isDevelopment} from 'utils/env'
import {loadScript} from 'utils/dom'

const DEBUG_STYLE = 'color: #0000ff; font-weight: bold; font-size: 13px;'

export const activateYandexMetrika = (
  id: number,
  params: Record<string, any>
): void => {
  if (isDebug()) {
    // inline log to prevent add clappr stuff to factory bundle
    // eslint-disable-next-line no-console
    console.log('%c[debug][init]', DEBUG_STYLE, id, params)
  }

  if (isDevelopment()) {
    return
  }

  if (!window.ym) {
    try {
      /* eslint-disable */
      // prettier-ignore
      // @ts-ignore
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      // prettier-ignore
      // @ts-ignore
      m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      /* eslint-enable */
    } catch {}
  }

  window.ym?.(id, 'init', params)
}

export const reachGoal = (
  id: number,
  target: string,
  params?: Record<string, any>
): void => {
  if (isDebug()) {
    // inline log to prevent add clappr stuff to factory bundle
    // eslint-disable-next-line no-console
    console.log('%c[debug][reachGoal]', DEBUG_STYLE, target, params)
  }

  if (isDevelopment()) {
    return
  }

  window.ym?.(id, 'reachGoal', ...[target, params].filter(Boolean))
}

export const setParams = (id: number, params: Record<string, any>): void => {
  if (isDebug()) {
    // inline log to prevent add clappr stuff to factory bundle
    // eslint-disable-next-line no-console
    console.log('%c[debug][params]', DEBUG_STYLE, params)
  }

  if (isDevelopment()) {
    return
  }

  window.ym?.(id, 'params', params)
}

type Top100Options = Record<string, any>

let loadTop100Script: null | Promise<any> = null
const top100Counters: Record<number, Promise<any>> = {}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const activateTop100 = (options: Top100Options = {}): void => {
  if (isDebug()) {
    // inline log to prevent add clappr stuff to factory bundle
    // eslint-disable-next-line no-console
    console.log('%c[debug][top100]', DEBUG_STYLE, options.project)
  }

  if (isDevelopment()) {
    return
  }

  const {project} = options

  if (!top100Counters.hasOwnProperty(project)) {
    top100Counters[project] = (async () => {
      try {
        if (!window.top100) {
          if (!loadTop100Script)
            loadTop100Script = loadScript(`//st.top100.ru/top100/top100.js`)

          try {
            await loadTop100Script
          } catch (error) {
            loadTop100Script = null
            throw error
          }
        }

        // eslint-disable-next-line new-cap
        const counter = new window.top100({
          ...options,
          checkInit: true
        })

        const initEventName = `initKraken${project}`

        await new Promise((resolve) => {
          const onInit = (): void => {
            document.removeEventListener(initEventName, onInit)
            resolve(null)
          }

          document.addEventListener(initEventName, onInit)
        })

        return counter
      } catch (error) {
        delete top100Counters[project]
        throw error
      }
    })()
  }
}

export const sendCustomVars = async (
  project: number,
  params: Record<string, string>
): Promise<void> => {
  if (isDebug()) {
    // inline log to prevent add clappr stuff to factory bundle
    // eslint-disable-next-line no-console
    console.log('%c[debug][sendCustomVars]', DEBUG_STYLE, params)
  }

  if (isDevelopment()) {
    return
  }

  try {
    const counter = await top100Counters[project]

    counter?.sendCustomVars(params)
  } catch {}
}
