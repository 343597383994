// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkZhwzHsAQ0rhDAPZu3l{position:fixed;border-radius:50%;background:#fff;transition:fill .2s,opacity .2s,color .2s;fill:#000;cursor:pointer;z-index:16777271}.h0e6YzLXvsgn2BFFMNDP{opacity:0}.h0e6YzLXvsgn2BFFMNDP:hover,iframe:hover+.h0e6YzLXvsgn2BFFMNDP{opacity:1}.sL6BcSsYwl0ZVJc4beDF{opacity:0 !important;pointer-events:none}`, "",{"version":3,"sources":["webpack://./src/widgets/player/styles.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,iBAAA,CACA,eAAA,CACA,yCAAA,CACA,SAAA,CACA,cAAA,CACA,gBAAA,CAGF,sBACE,SAAA,CAEA,+DAEE,SAAA,CAIJ,sBACE,oBAAA,CACA,mBAAA","sourcesContent":[".close {\n  position: fixed;\n  border-radius: 50%;\n  background: #fff;\n  transition: fill 0.2s, opacity 0.2s, color 0.2s;\n  fill: black;\n  cursor: pointer;\n  z-index: 16777271;\n}\n\n.hoverable {\n  opacity: 0;\n\n  &:hover,\n  iframe:hover + & {\n    opacity: 1;\n  }\n}\n\n.hidden {\n  opacity: 0 !important;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": `SkZhwzHsAQ0rhDAPZu3l`,
	"hoverable": `h0e6YzLXvsgn2BFFMNDP`,
	"hidden": `sL6BcSsYwl0ZVJc4beDF`
};
export default ___CSS_LOADER_EXPORT___;
