import {loadScript} from 'utils/dom'
import {getMediator} from './mediator'
import {RamblerPlayer} from './rambler-player'

const VERSION = process.env.VERSION

// handle custom version from url `rpversion` param and load this sdk
const version = window.location.search.match(/rpversion=([^&]+)(?:&|$)/)?.[1]

if (version && version !== VERSION) {
  const CDN_ORIGIN = process.env.CDN_ORIGIN
  const CDN_PREFIX = process.env.CDN_PREFIX
  const scriptSrc = `${CDN_ORIGIN}${CDN_PREFIX}/${version}/sdk.js`

  if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
    loadScript(scriptSrc)
  }
} else {
  if (Array.isArray(window.RamblerPlayer) || !window.RamblerPlayer) {
    const queue = window.RamblerPlayer || []

    getMediator()
    window.RamblerPlayer = RamblerPlayer
    queue.forEach((callback: () => void) => window.RamblerPlayer.push(callback))
  }
}
