import {loadScript} from 'utils/dom'

const getUserID = ((): (() => Promise<any>) => {
  let promise: Promise<any> | void

  return () => {
    if (!promise) {
      promise = (async () => {
        window.userID = window.userID || []

        try {
          const [userID] = await Promise.all([
            new Promise((resolve) => {
              window.userID.push(() => {
                // eslint-disable-next-line new-cap
                resolve(new window.userID())
              })
            }),
            loadScript('https://ssp.rambler.ru/user-id-sdk.js')
          ])

          return userID
        } catch (error) {
          promise = undefined
          throw error
        }
      })()
    }

    return promise
  }
})()

export const getFingerPrintUserId = async (): Promise<string> => {
  try {
    const userID = await getUserID()

    return userID.getFingerPrint()
  } catch {
    return ''
  }
}
