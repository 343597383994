let debug = false

export const setDebug = (value: boolean): void => {
  debug = value
}

export const isDevelopment = (): boolean =>
  process.env.NODE_ENV === 'development'

export const isStage = (): boolean =>
  Boolean(~process.env.CDN_ORIGIN.indexOf('vp-stage')) ||
  Boolean(~process.env.CDN_ORIGIN.indexOf('vp-dev'))

export const isDebug = (): boolean => debug || isDevelopment() || isStage()
